




























































import Vue from "vue";
import "@/assets/styles/custom_style.css";
import axios, { AxiosError } from "axios";
import { BackendURL } from "@/lib/auth";

interface IchaincodeTable {
  name: string;
  channelName: string;
  path?: string;
  transactionCount?: number;
  version: string;
  sequence: string;
}

export default Vue.extend({
  name: "ChaincodeLayout",

  data: () => ({
    currentChannel: Vue.prototype.$activeChannel,
    chaincodesTableHeader: [
      {
        text: "Smart contract name",
        align: "start",
        value: "name",
      },
      { text: "Channel Name", value: "channelName" },
      { text: "Version", value: "version" },
      { text: "Sequence", value: "sequence" },
      { text: "", value: "btn", sortable: false },
    ],
    chaincodesTable: [{}] as IchaincodeTable[],

    skeletonActive: true,
  }),

  watch: {
    $activeChannel(value) {
      this.currentChannel = value;
      this.skeletonActive = true;
      this.getChaincodes();
      this.skeletonActive = false;
    },
  },

  mounted() {
    this.getChaincodes();
    this.skeletonActive = false;
  },

  methods: {
    getChaincodes() {
      this.chaincodesTable = [];

      axios.defaults.withCredentials = true;
      axios
        .get(`${BackendURL}/channels/${this.currentChannel}/committed-sc`)
        .then((resp) => {
          const committedCCs: any[] = resp.data;
          committedCCs.forEach((chaincode) => {
            this.chaincodesTable.push({
              name: chaincode.smartContractName,
              sequence: chaincode.sequence,
              version: chaincode.smartContractVersion,
              channelName: this.currentChannel,
            });
          });
        })
        .catch((err: AxiosError) => {
          console.error(err);
          this.$root.$emit(
            "show-banner",
            "Could not retrieve chaincodes from the channel",
            false,
            "ERROR"
          );
        });
    },

    interactContract(row: any) {
      this.$router.push(`/chaincode/${row.channelName}/${row.name}`);
    },
  },
});
