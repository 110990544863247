


















































































import { BackendURL, IsLogged } from "@/lib/auth";
import axios from "axios";
import Vue from "vue";
import ChannelSelector from "@/components/ChannelSelector.vue";
import NotificationBadge from "@/components/NotificationBadge.vue";
import { Prop } from "vue/types/options";

export default Vue.extend({
  name: "TopNavBar",

  components: {
    ChannelSelector,
    NotificationBadge,
  },

  data: () => ({
    // User not logged in
    navButtons: [{ key: "login", icon: "mdi-login", link: "/login" }],
    tabs: [
      { key: "Dashboard", link: "/dashboard", group: "General" },
      { key: "Blocks", link: "/ledger/blocks", group: "Ledger" },
      { key: "Transactions", link: "/ledger/transactions", group: "Ledger" },
      { key: "Users", link: "/users", group: "Management" },
      { key: "Chaincodes", link: "/chaincodes", group: "Ledger" },
      { key: "Channels", link: "/channels", group: "Ledger" },
    ],

    profileList: [
      { key: "notifications", label: "Notifications", link: "/events" },
      { key: "metrics", label: "Metrics server", link: "/metrics/index" },
      { key: "view", label: "View profile", link: "/profile" },
      { key: "ccp", label: "Edit connection profile", link: "/profile/ccp" },
    ],

    isLogged: false,
  }),

  beforeMount() {
    if (IsLogged()) {
      this.isLogged = true;
    }
  },

  computed: {
    isMobile: function () {
      return ["sm", "xs", "md"].includes(this.$vuetify.breakpoint.name);
    },
  },

  props: {
    drawer: {
      type: Boolean as Prop<boolean>,
    },
  },

  model: {
    prop: "drawer",
  },

  methods: {
    activateMenu() {
      // Prepare the tabs and the groups to show in the vertical navigation bar
      let verticalTabs: any = {}; //tabs.group = []

      this.tabs.forEach((item) => {
        let group = item.group;
        const info = { key: item.key, link: item.link };
        if (verticalTabs[`${group}`]) {
          verticalTabs[`${group}`].push(info);
        } else {
          verticalTabs[`${group}`] = [];
          verticalTabs[`${group}`].push(info);
        }
      });

      // Add profile mangament tabs
      const cpy = Object.assign([], this.profileList);

      let newTabs = cpy.map((e: any) => {
        return { key: e.label, link: e.link, group: "Management" };
      });
      verticalTabs[`Management`] = verticalTabs[`Management`].concat(newTabs);
      this.$emit("activateSideMenuEvt", verticalTabs);
    },

    executeReq(key: string) {
      switch (key) {
        case "login":
          if (this.$route.path !== "/login") this.$router.push("/login");
          break;
        case "logout":
          if (IsLogged()) {
            axios.defaults.withCredentials = true;
            axios
              .get(BackendURL + "/logout")
              .then((resp) => {
                if (resp.status === 200) {
                  sessionStorage.clear();
                  window.location.replace("/login");
                }
              })
              .catch((err) => {
                sessionStorage.clear();
                window.location.replace("/login");
              });
          }
          break;

        default:
          // Go to profile ("profile")
          if (IsLogged()) {
            if (this.$route.path !== "/profile") {
              this.$router.push("/profile");
            }
          }
      }
    },

    checkButton(
      name: string,
      attrs: any,
      on: any,
      props: string,
      link: string
    ) {
      if (name === "profile") {
        switch (props) {
          case "v-bind":
            return attrs;
          case "v-on":
            return on;
          default:
            return {};
        }
      } else {
        if (props === "location") {
          this.goToRoute(link);
        }
      }
    },

    goToRoute(route: string) {
      if (this.$route.path !== route) {
        this.$router.push(route);
      }
    },
  },
});
