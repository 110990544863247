































import { BackendURL } from "@/lib/auth";
import axios from "axios";
import Vue from "vue";
export default Vue.extend({
  name: "NotificationBadge",

  data: () => ({
    numNotifications: 0,
  }),

  beforeMount() {
    this.getNotifications();
  },

  mounted() {
    const interval = 5 * 60 * 1000; // Query every 5 minutes
    this.$nextTick(function () {
      window.setInterval(() => {
        this.getNotifications();
      }, interval);
    });

    this.$root.$on("update-event-badge", () => {
      this.getNotifications();
    });
  },

  methods: {
    async getNotifications() {
      const channels: any[] = JSON.parse(
        sessionStorage.getItem("channels") as string
      );

      let allEvents: any[] = [];

      try {
        for (let i = 0; i < channels.length; i++) {          
          axios.defaults.withCredentials = true;
          const resp = await axios.get(
            `${BackendURL}/events/${channels[i]}/getAll`
          );

          allEvents = allEvents.concat(resp.data);
        }

        this.numNotifications = allEvents.filter(
          (element) => element.userStatus === "UNREAD"
        ).length;
      } catch (err) {
        console.log(err);
      }
    },
  },
});
