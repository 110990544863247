







import { BackendURL, IsLogged } from "@/lib/auth";
import axios from "axios";
import Vue from "vue";

export default Vue.extend({
  name: "Logout",
  data: () => ({}),

  beforeCreate() {
    sessionStorage.clear();
    localStorage.clear();

    axios.defaults.withCredentials = true;
    axios
      .get(`${BackendURL}/logout`)
      .then(() => {
        window.location.replace("/login");
      })
      .catch(() => {
        window.location.replace("/login");
      });
  },
});
