






































































import Vue from "vue";
import { prototype } from "vue/types/umd";

export default Vue.extend({
  name: "ChannelSelector",

  data: () => ({
    items: [] as string[],
    defaultValue: "" as string,
    selectedChannel: "" as string,
  }),

  beforeMount() {
    this.items = JSON.parse(
      sessionStorage.getItem("channels") as string
    ) as string[];

    if (sessionStorage.getItem("lastChannel") === null) {
      this.defaultValue = this.items[0];
      this.selectedChannel = this.items[0];

      Vue.set(Vue.prototype, "$activeChannel", this.items[0]);
    } else {
      const lastChannel = sessionStorage.getItem("lastChannel") as string;
      this.defaultValue = lastChannel;
      this.selectedChannel = lastChannel;
      Vue.set(Vue.prototype, "$activeChannel", lastChannel);
    }
  },

  methods: {
    selectChannel() {
      Vue.set(Vue.prototype, "$activeChannel", this.selectedChannel);
      sessionStorage.setItem("lastChannel", this.selectedChannel);
    },
  },
});
