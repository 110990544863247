

























import Vue from "vue";
export default Vue.extend({
  name: "InfoCard",

  props: {
    value: Number,
    title: String,
    icon: String,
    color: String,
  },
});
