






























































































































































































import Vue from "vue";
import "@/assets/styles/custom_style.css";
import axios, { AxiosError } from "axios";
import { BackendURL } from "@/lib/auth";

interface ITransactionBody {
  fn: string;
  args: Array<any>;
}

export default Vue.extend({
  name: "ChaincodeInteractionPanelLayout",

  data: () => ({
    currentChannel: Vue.prototype.$activeChannel,
    inputForm: [
      // { label: "Transaction arguments" },
      { label: "Transient data(optional)" },
    ],
    transactions: [{}] as string[],
    argumentsTx: [] as any[],
    currentFn: "",
    currentFnIndex: 0,
    argsResult: [],
    fabricResponse: null as any,
    btnDisabled: false,
  }),

  watch: {
    $activeChannel() {
      this.$router.push("/chaincodes");
    },
  },

  beforeMount() {
    this.getMetadata();
  },

  methods: {
    getMetadata() {
      this.transactions = [];
      this.argumentsTx = [];

      const chaincodeName = this.$route.params.chaincodeName;

      axios.defaults.withCredentials = true;
      axios
        .get(
          `${BackendURL}/channels/${this.currentChannel}/${chaincodeName}/metadata`
        )
        .then((resp) => {
          // The response have two smart contracts:
          //    - Our smart contract (This is to whom we want to perform the query)
          //    - "org.hyperledger.fabric"
          const chaincodes = resp.data.contracts;

          // We do not care about this smart contract
          delete chaincodes["org.hyperledger.fabric"];

          Object.keys(chaincodes).forEach((index) => {
            const transactionsObj: any[] = chaincodes[index].transactions;

            transactionsObj.forEach((fn) => {
              this.transactions.push(fn.name as string);

              let obj = [];
              if (fn.parameters) {
                obj = fn.parameters;
              } else {
                obj.push(null);
              }
              this.argumentsTx.push(obj);
            });

            this.currentFn = this.transactions[0];
            this.currentFnIndex = 0;
          });
        })
        .catch((err) => {
          console.error;
        });
    },

    handleSelect(value: any) {
      this.currentFn = value;
      this.currentFnIndex = this.transactions.indexOf(value);
      this.argsResult = [];
      this.fabricResponse = null;
    },

    createTransaction(transactionType: string) {
      this.btnDisabled = true;

      let body: ITransactionBody;

      // Check if there are any arguments
      if (this.argsResult) {
        body = {
          fn: this.currentFn,
          args: this.argsResult,
        };
      } else {
        body = {
          fn: this.currentFn,
          args: [""],
        };
      }

      axios.defaults.withCredentials = true;
      axios
        .post(
          `${BackendURL}/channels/${Vue.prototype.$activeChannel}/${this.$route.params.chaincodeName}/${transactionType}`,
          body
        )
        .then((resp) => {
          this.fabricResponse = resp.data
            ? resp.data
            : "Transaction does not return a value";
          this.btnDisabled = false;
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 401) {
            const text = err.response.data as string;
            this.$root.$emit("show-banner", text, false, "ERROR");
          } else {
            const text = err.response?.data
              ? err.response?.data
              : "Could not query the server";
            this.$root.$emit("show-banner", text, false, "ERROR");
          }
          this.btnDisabled = false;
        });
    },

    // submitTx(){},
  },
});
