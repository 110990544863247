

































































































































































































































import Vue from "vue";
import "@/assets/styles/custom_style.css";
import axios, { AxiosError } from "axios";
import { BackendURL } from "@/lib/auth";

import MyWorker from "worker-loader!@/workers/install.worker.ts";

interface IchaincodeRequest {
  sequence: number;
  smartContractName: string;
  smartContractVersion: string;
  packageId?: string;
  endorsementPlugin?: string;
  validationPlugin?: string;
  endorsementPolicy?: string;
  collectionConfig?: File;
  initRequired?: boolean;
  endorsersName?: string[];
}

export default Vue.extend({
  name: "InstallChaincodeLayout",

  data: () => ({
    rules: {
      required: (value: any) => !!value || "Required.",
      noSpaces: (v: any) => {
        let length = 1;
        if (v) {
          length = (v as string).split(" ").length;
        }

        return length === 1 || "No spaces allowed";
      },
      validVersion: (v: string) => {
        const versionString: string = v ? v : "";
        const regex = /^[0-9]+((\.)[0-9]+){0,3}$/;
        const isNotZeros = Number(versionString.split(".").join("")) > 0;
        const isValid = regex.test(versionString) && isNotZeros;
        return isValid || "Invalid Version";
      },
      number: (v: any) =>
        Number.isInteger(Number(v)) || "Must be an integer number",
    },

    formBody: {} as IchaincodeRequest,

    btnDisabled: false,
    loaderDialog: false,
  }),

  watch: {
    $activeChannel(value) {
      window.location.replace("/chaincodes");
    },
  },

  beforeMount() {
    const chaincodeName = this.$route.params["chaincodeName"];
    const splitted = chaincodeName.split("@");
    this.formBody.smartContractName = splitted[0];
    this.formBody.smartContractVersion = splitted[1];

  },

  methods: {
    initInstallationWorker() {
      const isValid: boolean = (this.$refs as any).installForm.validate();
      if (!isValid) return;

      this.btnDisabled = true;

      let worker = new MyWorker();

      let text = `Installing chaincode ${this.formBody.smartContractName}:${this.formBody.smartContractVersion} at ${Vue.prototype.$activeChannel}`;
      this.$root.$emit("show-banner", text, false, "INFO");

      const body: IchaincodeRequest = Object.assign({}, this.formBody);
      worker.postMessage({
        type: "install-chaincode",
        args: { body: body, channel: Vue.prototype.$activeChannel },
      });

      worker.onmessage = (e: any) => {
        if (e.data.isOk) {
          const text = `${this.formBody.smartContractName}:${this.formBody.smartContractVersion} installed at ${Vue.prototype.$activeChannel}`;
          this.$root.$emit("show-banner", text, true, "SUCCESS");

          if (this.$route.path === '/chaincodes/new') {
            window.location.replace('/chaincodes/new');
          }
        } else {
          const text = "Could not install chaincode";
          this.$root.$emit("show-banner", text, false, "ERROR");
        }
         this.btnDisabled = false;
        worker.terminate();
      };
    },
  },
});
