
































import Vue from "vue";
import EventChaincodeBanner from "./components/EventChaincodeBanner.vue";
import TopNavBar from "./components/TopNavBar.vue";
import { EventType } from "@/lib/types";
import VerticalNavBar from "./components/VerticalNavBar.vue";

interface IeventBanner {
  icon: string;
  text: string;
  active: boolean;
  color: string;
}

export default Vue.extend({
  name: "App",
  components: {
    TopNavBar,
    EventChaincodeBanner,
    VerticalNavBar,
  },

  data: () => ({
    eventList: [] as IeventBanner[],
    verticalElements: {},
  }),

  mounted() {
    this.$root.$on(
      "show-banner",
      (text: string, isInstalled: boolean, type: EventType) => {
        let icon = "";
        if (isInstalled) {
          icon = "mdi-alert-circle-check";
        } else {
          icon = "mdi-alert-circle";
        }

        this.eventList.push({
          active: true,
          text: text,
          color: type.toLowerCase(),
          icon: icon,
        });
      }
    );
  },

  beforeDestroy() {
    this.$root.$off("show-banner");
    this.$root.$off("update-event-badge");
  },


  methods: {
    activateMenu(elements: any) {      
      this.verticalElements = elements;
      (this.$refs.vertical as any).drawer = true;
    },

    closeChaincodeBanner(index: number) {
      let o = this.eventList.splice(index, 1);      
    },

  },
});
