











































































































import Vue from "vue";
import "@/assets/styles/custom_style.css";
import axios from "axios";
import { BackendURL } from "@/lib/auth";

export default Vue.extend({
  name: "NotificationsLayout",

  data: () => ({
    eventsTableHeader: [
      {
        text: "Date",
        align: "start",
        value: "timestamp",
        filterable: false,
        sortable: true,
      },
      {
        text: "Channel Name",
        value: "channelID",
        sortable: true,
        filterable: true,
      },
      { text: "Creator", value: "msp", filterable: true },
      { text: "Type", value: "requestType", filterable: true },
      { text: "Status", value: "userStatus", sortable: true, filterable: true },
      {
        text: "Show more info",
        value: "infoBtn",
        sortable: false,
        filterable: false,
      },
      {
        text: "Mark as read",
        value: "readBtn",
        sortable: false,
        filterable: false,
      },
    ],
    eventsTypes: ["UNREAD", "READ", "CREATOR"],
    eventsTables: {} as any,

    moreInfoDialog: false,
    dialogTableHeader: [
      { text: "Name", align: "start", value: "name", sortable: false },
      { text: "Value", align: "start", value: "value", sortable: false },
    ],
    dialogTable: [] as any[],

    filterEventTable: "",
    btnDisabled: false,
  }),

  beforeMount() {
    this.getEvents();
  },

  methods: {
    async getEvents() {
      const channels: any[] = JSON.parse(
        sessionStorage.getItem("channels") as string
      );

      this.eventsTables = {};
      let events: any[] = [];
      try {
        for (let i = 0; i < channels.length; i++) {
          const channelID = channels[i];
          axios.defaults.withCredentials = true;
          const resp = await axios.get(
            `${BackendURL}/events/${channelID}/getAll`
          );

          if (resp.data.length > 0) {
            events = events.concat(resp.data);
          }
        }

        let AllTables: any = {};
        for (let i = 0; i < this.eventsTypes.length; i++) {
          const type = this.eventsTypes[i];
          AllTables[type] = [];
          AllTables[type] = ([] as any[]).concat(
            events.filter((e) => e.userStatus === type)
          );
        }

        this.eventsTables = Object.assign({}, AllTables as any);
      } catch (err) {
        console.log(err);
        const text = `Could not get notifications from server`;
        this.$root.$emit("show-banner", text, false, "ERROR");
      }
    },

    formatDate(timestamp: number) {
      return new Date(timestamp).toLocaleString();
    },

    async markEvent(event: any) {
      if (event.userStatus === "READ") {
        return;
      }

      this.btnDisabled = true;

      axios.defaults.withCredentials = true;
      try {
        await axios.get(
          `${BackendURL}/events/${event.channelID}/evt/${event._id}/mark?option=READ`
        );
        await this.getEvents();
        this.$root.$emit("update-event-badge");
      } catch (err) {
        console.log(err);
        const text = `Could not mark notification`;
        this.$root.$emit("show-banner", text, false, "ERROR");
      }

      this.btnDisabled = false;
    },

    openDialog(event: any) {
      this.moreInfoDialog = true;
      this.dialogTable = [];

      Object.keys(event).forEach((key) => {
        let value = event[key];
        if (key === "expirationTime") {
          value = new Date(event[key] * 1000).toLocaleString();
        }

        if (key === "timestamp") {
          value = new Date(event[key]).toLocaleString();
        }

        this.dialogTable.push({
          name: key,
          value: value,
        });
      });
    },

    async markAll() {
      const eventsCpy: any = Object.assign({}, this.eventsTables);
      const unreadItems = eventsCpy["UNREAD"];

      if (unreadItems.length === 0) return;

      this.btnDisabled = true;
      try {
        for (let i = 0; i < unreadItems.length; i++) {
          const event: any = unreadItems[i];
          axios.defaults.withCredentials = true;
          await axios.get(
            `${BackendURL}/events/${event.channelID}/evt/${event._id}/mark?option=READ`
          );
        }
      } catch (err) {
        console.log(err);
        const text = `Could not mark all events due to: ${err}`;
        this.$root.$emit("show-banner", text, false, "ERROR");
      }

      await this.getEvents();
      this.$root.$emit("update-event-badge");

      this.btnDisabled = false;
    },
  },
});
