























































































































import Vue from "vue";
import { BackendURL, IsLogged } from "@/lib/auth";

import "@/assets/styles/custom_style.css";
import axios, { AxiosError } from "axios";

interface IregistrationForm {
  userID: string;
  password: string;
  enrollmentID: string;
  enrollmentSecret: string;
  mspID: string;
  organization: string;
  caName: string;
  isAdmin: boolean;
  peerName: string;
  networkID: string;
  gwAsLocalHost: boolean;
  gwDiscoveryEnabled: boolean;
  ccp: any;
}

export default Vue.extend({
  name: "RegisterLayout",

  data: () => ({
    registerProperties: [
      {
        name: "userID",
        label: "Email",
        required: true,
        hint: "email account of the user",
        isSelect: false,
      },
      {
        name: "password",
        label: "Password",
        required: true,
        hint: "password of the user",
        isSelect: false,
      },
      {
        name: "enrollmentID",
        label: "Enrollment ID",
        required: true,
        hint: "Enrollment ID used to enroll the user in the organization's CA",
        isSelect: false,
      },
      {
        name: "enrollmentSecret",
        label: "EnrollmentSecret",
        required: true,
        hint: "Enrollment secret used to enroll the user in the organization's CA",
        isSelect: false,
      },
      {
        name: "mspID",
        label: "Membership ID(MSPID)",
        required: true,
        hint: "Membership ID of the organization",
        isSelect: false,
      },
      {
        name: "organization",
        label: "organization",
        required: true,
        hint: "Name of the organization",
        isSelect: false,
      },
      {
        name: "networkID",
        label: "Network ID",
        required: true,
        hint: "ID of the network (All the parties involved in the Blockchain should have the same network ID)",
        isSelect: false,
      },
      {
        name: "caName",
        label: "CA Name",
        required: true,
        hint: "Name of the organization's CA",
        isSelect: false,
      },
      {
        name: "peerName",
        label: "Name of the peer",
        required: true,
        hint: "Name of the peer in the connection profile used for interacting with the Blockchain",
        isSelect: false,
      },
      {
        name: "gwAsLocalHost",
        label: "Use localhost settings",
        required: true,
        hint: "True if the network is hosted on localhost, false otherwise",
        isSelect: true,
      },
      {
        name: "gwDiscoveryEnabled",
        label: "Use discovery service",
        required: true,
        hint: "True if you want to use the dafault discovery service of Hyperledger Fabric(Recommended).",
        isSelect: true,
      },
    ],
    ccpFile: undefined,

    completeForm: {
      userID: "",
      password: "",
      enrollmentID: "",
      enrollmentSecret: "",
      mspID: "",
      organization: "",
      caName: "",
      peerName: "",
      networkID: "",
      gwAsLocalHost: false,
      gwDiscoveryEnabled: true,
      ccp: undefined,
    } as IregistrationForm,

    rules: {
      required: (value: any) => !!value || "Required.",
      email: (value: any) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
  }),

  beforeMount() {
    if (IsLogged()) {
      window.location.replace("/dashboard");
    }
  },

  methods: {
    submitForm() {
      const isValid: boolean = (this.$refs as any).registrationForm.validate();
      if (!isValid) {
        return;
      }

      const body = Object.assign({}, this.completeForm);
      const file: File = this.ccpFile as any;

      file.text().then((ccp) => {
        try {
          body.ccp = JSON.parse(ccp);
        } catch (err) {
          const text = "Connection profile format is not valid";
          this.$root.$emit("show-banner", text, false, "ERROR");
          return;
        }

        axios.defaults.withCredentials = true;
        axios
          .post(`${BackendURL}/register`, body)
          .then(() => {
            window.location.replace("/");
          })
          .catch((err: AxiosError) => {
            console.log(err);
            const text = "Could not submit registration formulary to server";
            this.$root.$emit("show-banner", text, false, "ERROR");
          });
      });
    },

    clearForm() {
      this.completeForm = {} as any;
      this.ccpFile = undefined as any;
    },
  },
});
