

















import Vue from "vue";
export default Vue.extend({
  name: "EventChaincodeBanner",

  props: {
    active: Boolean,
    text: String,
    statusIcon: String,
    color: String,
  },

  data: () => ({
    opened: false,
  }),

  methods: {
    closeBanner() {
      this.$emit("close-chaincode-event");
    },
  },
});
