





























































import Vue from "vue";
export default Vue.extend({
  name: "DatePicker",

  data: () => ({
    intervals: [
      "Last 5 minutes",
      "Last 15 minutes",
      "Last 30 minutes",
      "Last 1 hour",
      "Last 3 hours",
      "Last 6 hours",
      "Last 12 hours",
      "Last 24 hours",
      "Last 2 days",
      "Last 5 days",
      "Last 10 days",
      "Last 15 days",
    ],

    selectedIndex: 0,
    selectedDate: "",
  }),

  beforeMount() {
    this.selectedIndex = 8;
    this.selectedDate = this.intervals[this.selectedIndex];

    this.changeDate(this.selectedDate, this.selectedIndex);
  },

  methods: {
    calculeStartTimestamp(value: string): any {
      // default step 900s (15 min)
      let date = {start: 0, end: Math.round(Date.now() / 1000), step: 900};
      switch (value) {
        case this.intervals[0]:
          date.start = Math.round(Date.now() / 1000 - 5 * 60);
          date.step = 15;
          break;
        case this.intervals[1]:
          date.start = Math.round(Date.now() / 1000 - 15 * 60);
          date.step = 15;
          break;
        case this.intervals[2]:
          date.start = Math.round(Date.now() / 1000 - 30 * 60);
          date.step = 15;
          break;
        case this.intervals[3]:
          date.start = Math.round(Date.now() / 1000 - 60 * 60);
          date.step = 10*60;
          break;
        case this.intervals[4]:
          date.start = Math.round(Date.now() / 1000 - 3 * 60 * 60);
          break;
        case this.intervals[5]:
          date.start = Math.round(Date.now() / 1000 - 6 * 60 * 60);
          break;
        case this.intervals[6]:
          date.start = Math.round(Date.now() / 1000 - 12 * 60 * 60);
          break;
        case this.intervals[7]:
          date.start = Math.round(Date.now() / 1000 - 24 * 60 * 60);
          break;
        case this.intervals[8]:
          date.start = Math.round(Date.now() / 1000 - 2 * 24 * 60 * 60);
          break;
        case this.intervals[9]:
          date.start = Math.round(Date.now() / 1000 - 5 * 24 * 60 * 60);
          date.step = 5 * 60 * 60;
          break;
        case this.intervals[10]:
          date.start = Math.round(Date.now() / 1000 - 10 * 24 * 60 * 60);
          date.step = 5 * 60 * 60;
          break;
        case this.intervals[11]:
          date.start = Math.round(Date.now() / 1000 - 15 * 24 * 60 * 60);
          date.step = 5 * 60 * 60;
          break;
        default:
          break;
      }
      
      return date.start !== 0 ? date: null;
    },

    changeDate(item: string, index: number) {
      this.selectedIndex = index;
      this.selectedDate = item;

      let date = this.calculeStartTimestamp(item);
      

      if (date) {        
        this.$emit("event-active-date", date);
      }
    },
  },
});
