





















































































































































































































import InfoCard from "@/components/InfoCard.vue";
import DatePicker from "@/components/DatePicker.vue";
import Vue from "vue";
import "@/assets/styles/custom_style.css";
import axios, { AxiosError } from "axios";
import { BackendURL } from "@/lib/auth";
import { WaitTime } from "@/lib/common";

import VueApexCharts from "vue-apexcharts";
import vuetify from "@/plugins/vuetify";

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

export default Vue.extend({
  name: "Dashboard",
  data: () => ({
    isLoading: true,
    metricsCards: [] as any[],
    listPeers: [] as any[],
    listPeersTableHeader: [
      {
        text: "Peer Name",
        align: "start",
        value: "name",
        divider: true,
        class: "d-flex justify-center align-center",
        sortable: false,
      },
    ],

    peersSortBy: "name",
    peersSortDesc: false,

    blocksTableHeader: [
      {
        text: "Number",
        align: "start",
        value: "Number",
        sortable: true,
      },
      { text: "Data Hash", value: "DataHash", sortable: false },
      { text: "Block Hash", value: "BlockHash", sortable: false },
      {
        text: "Previous Hash",
        value: "PreviousHash",
        sortable: false,
      },
      {
        text: "Number Transactions",
        value: "NumTx",
        sortable: false,
      },
      { text: "Timestamp", value: "Timestamp", sortable: true },
    ],
    blocksTable: [] as any[],
    blocksSortBy: "Number",
    blocksSortDesc: true,

    metricServer: "",
    hasMetricServer: false,
    metricsChartOpts: [] as any[],

    dateOptsStruct: {
      start: Math.round(Date.now() / 1000 - 2 * 24 * 60 * 60),
      end: Math.round(Date.now() / 1000),
      step: 900,
    },
  }),

  components: {
    InfoCard,
    DatePicker,
  },

  watch: {
    $activeChannel() {
      this.isLoading = true;
      this.getInfo().then(() => {
        WaitTime(500).then(() => {
          this.isLoading = false;
        });
      });
    },
  },

  beforeMount() {
    console.log(`${BackendURL}`);
    
    this.isLoading = true;
    this.getMetrics();
    this.getInfo().then(() => {
      WaitTime(500).then(() => {
        this.isLoading = false;
      });
    });
  },

  methods: {
    async getInfo() {
      this.metricsCards = [];
      this.blocksTable = [];
      const channel = Vue.prototype.$activeChannel;
      const mspID = JSON.parse(sessionStorage.getItem("profile") as string).org;

      axios.defaults.withCredentials = true;

      // Number of blocks
      try {
        const resp = await axios.get(
          `${BackendURL}/channels/${channel}/discover`
        );

        const height =
          resp.data.peers_by_org[`${mspID}`].peers[0].ledgerHeight.low;

        const numberUsers = Object.keys(resp.data.peers_by_org).length;

        // Get all users
        this.listPeers = [];
        Object.keys(resp.data.peers_by_org).forEach((org) => {
          (resp.data.peers_by_org[org].peers as any[]).forEach((peer) => {
            this.listPeers.push({ name: peer.name });
          });
        });

        this.metricsCards.push(
          {
            title: "BLOCKS",
            value: height,
            icon: "mdi-vector-link",
            color: "primary",
          },
          {
            title: "ORGS",
            value: numberUsers,
            icon: "mdi-account-group",
            color: "success",
          }
        );
      } catch (err) {
        console.log(err);
        this.metricsCards.push(
          {
            title: "BLOCKS",
            value: "undefined",
            icon: "mdi-vector-link",
            color: "primary",
          },
          {
            title: "ORGS",
            value: "undefined",
            icon: "mdi-account-group",
            color: "success",
          }
        );
        this.$root.$emit(
          "show-banner",
          "Could not discover network",
          false,
          "ERROR"
        );
      }

      // Number of users
      try {
        const resp = await axios.get(`${BackendURL}/ca/users/list`);
        const length = resp.data.result.identities.length;
        this.metricsCards.push({
          title: "USERS IN ORG",
          value: length,
          icon: "mdi-nature-people",
          color: "indigo",
        });
      } catch (err) {
        const dataError = (err as AxiosError).response?.data
          ? (err as AxiosError).response?.data
          : null;

        const regex =
          /\[\[{"code":71,"message":"Authorization failure"}\]\]/;

        if (dataError && regex.exec(dataError)) {
          this.metricsCards.push({
            title: "USERS IN ORG",
            value: "undefined",
            icon: "mdi-nature-people",
            color: "indigo",
          });          
        } else {
          this.metricsCards.push({
            title: "USERS IN ORG",
            value: "undefined",
            icon: "mdi-nature-people",
            color: "indigo",
          });
          this.$root.$emit(
            "show-banner",
            "Could not retrieve list of users from server",
            false,
            "ERROR"
          );
        }
      }

      // Number of users
      try {
        const resp = await axios.get(
          `${BackendURL}/channels/${channel}/committed-sc`
        );
        const length = resp.data.length;

        this.metricsCards.push({
          title: "CHAINCODES",
          value: length,
          icon: "mdi-package",
          color: "error",
        });
      } catch (err) {
        console.log(err);
        this.metricsCards.push({
          title: "CHAINCODES",
          value: "undefined",
          icon: "mdi-package",
          color: "error",
        });
        this.$root.$emit(
          "show-banner",
          "Could not get committed chaincodes from server",
          false,
          "ERROR"
        );
      }

      // Blocks table
      try {
        const resp = await axios.get(
          `${BackendURL}/channels/${channel}/ledger/get`
        );

        this.blocksTable = Object.assign([], resp.data);
      } catch (err: any) {
        if (!err.isAxiosError) {
          this.$root.$emit(
            "show-banner",
            "Could not retrieve block from server",
            false,
            "ERROR"
          );
        }
      }
    },

    async getMetrics() {
      let metricServer = undefined;
      this.metricsChartOpts = [];

      try {
        axios.defaults.withCredentials = true;
        const resp = await axios.get(`${BackendURL}/metrics/get`);
        metricServer = resp.data[0].prometheusServer;
        this.hasMetricServer = true;
      } catch (err: any) {
        console.log(err);
        if (err.response?.status === 515) {
          this.metricServer = "Not defined";
          return;
        }
        const text = "Could not retrieve metric server from server";
        this.$root.$emit("show-banner", text, false, "ERROR");
        return;
      }

      // Get ledger height data from the last two days
      try {
        const body = {
          type: "query_range",
          parameter: "ledger_blockchain_height",
          start: this.dateOptsStruct.start,
          end: this.dateOptsStruct.end,
          step: this.dateOptsStruct.step, // 15 min
        };

        axios.defaults.withCredentials = true;
        const resp = await axios.post(`${BackendURL}/metrics/query`, body);
        const arrayAllTargets: any[] = resp.data.data.result;

        // Gets the channels
        const channels = [
          ...new Set(arrayAllTargets.map((a) => a.metric.channel)),
        ];

        const arrayChartSeries: any[] = [];
        let xValues: string[] = [];
        channels.forEach((ch) => {
          const valuesCh = arrayAllTargets.filter(
            (item) => item.metric.channel.indexOf(ch) > -1
          );

          xValues = valuesCh[0].values.map((value: any) => value[0] * 1000);
          const yValues: Array<number> = valuesCh[0].values.map(
            (value: any) => value[1]
          );

          arrayChartSeries.push({
            name: ch,
            data: yValues,
          });
        });

        this.metricsChartOpts.push({
          height: 300,
          opts: {
            chart: {
              id: `chart`,
              toolbar: {
                show: true,
              },
            },
            xaxis: {
              title: { text: "Date" },
              categories: xValues,
              type: "datetime",
              labels: {
                datetimeUTC: false,
              },
            },
            yaxis: {
              title: { text: "Blocks" },
            },
            title: {
              text: `Blockchain ledger height`,
              align: "left",
            },
            grid: {
              xaxis: {
                lines: {
                  show: true,
                },
              },
            },
            stroke: {
              curve: "stepline",
            },
            tooltip: {
              x: {
                format: "HH:mm dd/MM/yy",
              },
            },
            responsive: [
              {
                breakpoint: this.$vuetify.breakpoint.mobileBreakpoint,
                options: {
                  chart: {
                    toolbar: {
                      show: true,
                      tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false,
                      },
                    },
                  },
                  title: {
                    align: "center",
                  },
                },
              },
            ],
          },

          series: arrayChartSeries,
        });
      } catch (err) {
        console.log(err);
        const text = 'Could not get metric "ledger_blockchain_height" ';
        this.$root.$emit("show-banner", text, false, "ERROR");
      }

      try {
        const numDays = 2; // days
        const body = {
          type: "query_range",
          parameter: "ledger_transaction_count",
          start: this.dateOptsStruct.start,
          end: this.dateOptsStruct.end,
          step: this.dateOptsStruct.step, // 15 min
        };

        axios.defaults.withCredentials = true;
        const resp = await axios.post(`${BackendURL}/metrics/query`, body);
        const result: any[] = resp.data.data.result;

        // Get all channels
        const channels = [...new Set(result.map((a) => a.metric.channel))];

        let arrayChartSeries: any = {};
        let xValues: any[] = [];
        channels.forEach((ch) => {
          const valuesCh = result.filter(
            (item) => item.metric.channel.indexOf(ch) > -1
          );

          // Get all the chaincodes in the channel
          const chaincodesInCh = [
            ...new Set(valuesCh.map((a) => a.metric.chaincode)),
          ];

          // Get ledger height per chaincode and per channel
          arrayChartSeries[ch] = [];
          chaincodesInCh.forEach((chaincode) => {
            const values = result.filter(
              (item) => item.metric.chaincode.indexOf(chaincode) > -1
            );

            // Get X and Y values
            xValues = values[0].values.map((value: any) => value[0] * 1000);
            const yValues: Array<number> = values[0].values.map(
              (value: any) => value[1]
            );

            arrayChartSeries[ch].push({
              name: chaincode !== "unknown" ? chaincode : "CONFIG",
              data: yValues,
            });
          });

          // Draw chart
          this.metricsChartOpts.push({
            height: 300,
            opts: {
              chart: {
                id: `chart`,
                toolbar: {
                  show: true,
                },
              },
              xaxis: {
                title: { text: "Date" },
                categories: xValues,
                type: "datetime",
                labels: {
                  datetimeUTC: false,
                },
              },
              yaxis: {
                title: { text: "Blocks" },
              },
              title: {
                text: `Number of transactions in ${ch}`,
                align: "left",
              },
              grid: {
                xaxis: {
                  lines: {
                    show: true,
                  },

                  labels: {
                    datetimeUTC: false,
                  },
                },
              },
              stroke: {
                curve: "stepline",
              },
              tooltip: {
                x: {
                  format: "HH:mm dd/MM/yy",
                },
              },

              responsive: [
                {
                  breakpoint: this.$vuetify.breakpoint.mobileBreakpoint,
                  options: {
                    chart: {
                      toolbar: {
                        show: true,
                        tools: {
                          download: true,
                          selection: false,
                          zoom: false,
                          zoomin: false,
                          zoomout: false,
                          pan: false,
                          reset: false,
                        },
                      },
                    },
                    title: {
                      align: "center",
                    },
                  },
                },
              ],
            },

            series: arrayChartSeries[ch],
          });
        });
      } catch (err) {
        console.log(err);
        const text = 'Could not get metric "ledger_transaction_count"';
        this.$root.$emit("show-banner", text, false, "ERROR");
      }
    },

    calculateColSize(index: number, length: number): number {
      if (length % 2 && index !== 0) return 6;
      else return 12;
    },

    changeDate(date: any) {
      Object.assign(this.dateOptsStruct, date);
      this.getMetrics();
    },
  },
});
