























































































































































import Vue from "vue";
import "@/assets/styles/custom_style.css";
import axios, { AxiosError } from "axios";
import { BackendURL } from "@/lib/auth";

export default Vue.extend({
  name: "MetricsLayout",

  data: () => ({
    defaultValue: "",
    serverURL: "",
    btnDisabled: false,
    editBtn: false,
    deleteBtn: false,

    deleteDialog: false,

    rules: {
      required: (value: any) => !!value || "Required.",
      url: (value: string) => {
        let url;
        try {
          url = new URL(value);
        } catch (_) {
          return "Must be a valid URL";
        }

        return url.protocol === "http:" || url.protocol === "https:";
      },
    },

    listTargetsHeader: [{ text: "target", align: "center", value: "name" }],
    listTargets: [] as any[],

    listMetricsTable: [
      {
        name: "chaincode_execute_timeouts",
        type: "counter",
        description:
          "The number of chaincode executions (Init or Invoke) that have timed out",
      },
      {
        name: "chaincode_launch_duration",
        type: "histogram",
        description: "The time to launch a chaincode",
      },
      {
        name: "chaincode_launch_failures",
        type: "counter",
        description: "The number of chaincode launches that have failed",
      },
      {
        name: "chaincode_shim_request_duration",
        type: "histogram",
        description: "The time to complete chaincode shim requests",
      },
      {
        name: "chaincode_shim_requests_completed",
        type: "counter",
        description: "The number of chaincode shim requests completed",
      },
      {
        name: "chaincode_shim_requests_received",
        type: "counter",
        description: "The number of chaincode shim requests received",
      },
      {
        name: "couchdb_processing_time",
        type: "histogram",
        description:
          "Time taken in seconds for the function to complete request to CouchDB",
      },
      {
        name: "deliver_blocks_sent",
        type: "counter",
        description: "The number of blocks sent by the deliver service",
      },
      {
        name: "deliver_requests_completed",
        type: "counter",
        description: "The number of deliver requests that have been completed",
      },
      {
        name: "deliver_requests_received",
        type: "counter",
        description: "The number of deliver requests that have been received",
      },
      {
        name: "deliver_streams_closed",
        type: "counter",
        description:
          "The number of GRPC streams that have been closed for the deliver service",
      },
      {
        name: "deliver_streams_opened",
        type: "counter",
        description:
          "The number of GRPC streams that have been opened for the deliver service",
      },
      {
        name: "dockercontroller_chaincode_container_build_duration",
        type: "histogram",
        description: "The time to build a chaincode image in seconds",
      },
      {
        name: "endorser_chaincode_instantiation_failures",
        type: "counter",
        description:
          "The number of chaincode instantiations or upgrade that have failed",
      },

      {
        name: "endorser_endorsement_failures",
        type: "counter",
        description: "The number of failed endorsements",
      },
      {
        name: "endorser_proposal_acl_failures",
        type: "counter",
        description: "The number of proposals that failed ACL checks",
      },
      {
        name: "endorser_proposal_duration",
        type: "counter",
        description: "The time to complete a proposal",
      },
      {
        name: "endorser_proposal_simulation_failures",
        type: "counter",
        description: "The number of failed proposal simulations",
      },
      {
        name: "endorser_proposal_validation_failures",
        type: "counter",
        description:
          "The number of proposals that have failed initial validation",
      },
      {
        name: "endorser_proposals_received",
        type: "counter",
        description: "The number of proposals received",
      },
      {
        name: "endorser_successful_proposals",
        type: "counter",
        description: "The number of successful proposals",
      },
      {
        name: "fabric_version",
        type: "gauge",
        description: "The active version of Fabric",
      },
      {
        name: "gossip_comm_messages_received",
        type: "counter",
        description: "Number of messages received",
      },
      {
        name: "gossip_comm_messages_sent",
        type: "counter",
        description: "Number of messages sent",
      },
      {
        name: "gossip_comm_overflow_count",
        type: "counter",
        description: "Number of outgoing queue buffer overflows",
      },
      {
        name: "gossip_leader_election_leader",
        type: "gauge",
        description: "Peer is leader (1) or follower (0)",
      },
      {
        name: "gossip_membership_total_peers_known",
        type: "gauge",
        description: "Total known peers",
      },
      {
        name: "gossip_payload_buffer_size",
        type: "gauge",
        description: "Size of the payload buffer",
      },
      {
        name: "gossip_privdata_commit_block_duration",
        type: "histogram",
        description:
          "Time it takes to commit private data and the corresponding block (in seconds)",
      },
      {
        name: "gossip_privdata_fetch_duration",
        type: "histogram",
        description:
          "Time it takes to fetch missing private data from peers (in seconds)",
      },
      {
        name: "gossip_privdata_list_missing_duration",
        type: "histogram",
        description:
          "Time it takes to list the missing private data (in seconds)",
      },
      {
        name: "gossip_privdata_pull_duration",
        type: "histogram",
        description:
          "Time it takes to pull a missing private data element (in seconds)",
      },
      {
        name: "gossip_privdata_purge_duration",
        type: "histogram",
        description: "Time it takes to purge private data (in seconds)",
      },
      {
        name: "gossip_privdata_reconciliation_duration",
        type: "histogram",
        description:
          "Time it takes for reconciliation to complete (in seconds)",
      },
      {
        name: "gossip_privdata_retrieve_duration",
        type: "histogram",
        description:
          "Time it takes to retrieve missing private data elements from the ledger (in seconds)",
      },
      {
        name: "gossip_privdata_send_duration",
        type: "histogram",
        description:
          "Time it takes to send a missing private data element (in seconds)",
      },
      {
        name: "gossip_privdata_validation_duration",
        type: "histogram",
        description: "Time it takes to validate a block (in seconds)",
      },
      {
        name: "gossip_state_commit_duration",
        type: "histogram",
        description: "Time it takes to commit a block in seconds",
      },
      {
        name: "gossip_state_height",
        type: "gauge",
        description: "Current ledger height",
      },
      {
        name: "grpc_comm_conn_closed",
        type: "counter",
        description:
          "gRPC connections closed. Open minus closed is the active number of connections",
      },
      {
        name: "grpc_comm_conn_opened",
        type: "counter",
        description:
          "gRPC connections opened. Open minus closed is the active number of connections",
      },
      {
        name: "grpc_server_stream_messages_received",
        type: "counter",
        description: "The number of stream messages received",
      },
      {
        name: "grpc_server_stream_requests_received",
        type: "counter",
        description: "The number of stream requests received",
      },
      {
        name: "grpc_server_unary_request_duration",
        type: "histogram",
        description: "The time to complete a unary request",
      },
      {
        name: "grpc_server_unary_requests_completed",
        type: "counter",
        description: "The number of unary requests completed",
      },
      {
        name: "grpc_server_unary_requests_received",
        type: "counter",
        description: "The number of unary requests received",
      },
      {
        name: "ledger_block_processing_time",
        type: "histogram",
        description: "Time taken in seconds for ledger block processing",
      },
      {
        name: "ledger_blockchain_height",
        type: "gauge",
        description: "Height of the chain in blocks",
      },
      {
        name: "ledger_blockstorage_and_pvtdata_commit_time",
        type: "histogram",
        description:
          "Time taken in seconds for committing the block and private data to storage",
      },
      {
        name: "ledger_blockstorage_commit_time",
        type: "histogram",
        description:
          "Time taken in seconds for committing the block to storage",
      },
      {
        name: "ledger_statedb_commit_time",
        type: "histogram",
        description:
          "Time taken in seconds for committing block changes to state db",
      },
      {
        name: "ledger_transaction_count",
        type: "counter",
        description: "Number of transactions processed",
      },
      {
        name: "logging_entries_checked",
        type: "counter",
        description:
          "Number of log entries checked against the active logging level",
      },
      {
        name: "logging_entries_written",
        type: "counter",
        description: "Number of log entries that are written",
      },
    ],
    listMetricsTableHeader: [
      {
        text: "Name",
        align: "start",
        value: "name",
        filterable: true,
      },
      { text: "Type", value: "type", filterable: false },
      {
        text: "Description",
        value: "description",
        sortable: false,
        filterable: false,
      },
    ],
    searchMetric: "",
  }),

  beforeMount() {
    this.getMetricServer();
    this.getPrometheusPeers();
  },

  methods: {
    async getMetricServer() {
      axios.defaults.withCredentials = true;
      try {
        const resp = await axios.get(`${BackendURL}/metrics/get`);
        this.serverURL = resp.data[0].prometheusServer;
      } catch (err: any) {
        if (err.response?.status === 515) {
          this.serverURL = "Not defined";
          return;
        }
        const text = "Could not retrieve metric server from server";
        this.$root.$emit("show-banner", text, false, "ERROR");
      }

      this.defaultValue = this.serverURL;
    },

    clearEditMode() {
      this.btnDisabled = true;
      this.editBtn = false;

      this.serverURL = this.defaultValue.split("").join("");

      this.btnDisabled = false;
    },

    submitServer() {
      const isValid = (this.$refs as any).newServerForm.validate();
      if (!isValid) return;
      if (this.serverURL === "Not defined" || this.serverURL === "" || this.serverURL === undefined) return;

      this.btnDisabled = true;

      axios.defaults.withCredentials = true;
      axios
        .post(`${BackendURL}/metrics/new`, { prometheusServer: this.serverURL })
        .catch((err) => {
          console.log(err);
          const text = "Could not submit the URL to server";
          this.$root.$emit("show-banner", text, false, "ERROR");
        })
        .finally(() => {
          this.defaultValue = this.serverURL;
          this.clearEditMode();
        });
    },

    deleteServer() {
      axios.defaults.withCredentials = true;
      axios
        .get(`${BackendURL}/metrics/delete`)
        .catch((err) => {
          console.log(err);
          this.deleteDialog = false;
          const text = "Could not delete the URL to server";
          this.$root.$emit("show-banner", text, false, "ERROR");
        })
        .finally(() => {
          this.deleteDialog = false;
          this.getMetricServer();
        });
    },

    async getPrometheusPeers() {
      if (this.serverURL === "Not defined" || this.serverURL === "" || this.serverURL === undefined) return;

      axios.defaults.withCredentials = true;
      try {
        const resp = await axios.post(`${BackendURL}/metrics/query`, { type: "targets" });
        const targets = resp.data.data.activeTargets as any[];
        targets.forEach((target) => {
          this.listTargets.push({name: target.labels.instance});
        });
        
      } catch (err) {
        console.log(err);
        const text = "Could not retrieve targets from server";
        this.$root.$emit("show-banner", text, false, "ERROR");
        throw err;
      }
    },
  },
});
