import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import { IsLogged } from "@/lib/auth";

// Components
import Profile from "../views/Profile.vue";
import Channels from "../views/Channels.vue";
import Home from "../views/Home.vue";
import Dashboard from "../views/Dashboard.vue";
import LoginLayout from "../components/LoginLayout.vue";
import Logout from "../views/Logout.vue";
import ChaincodesLayout from "@/views/Chaincode.vue";
import ChaincodeInteractionPanelLayout from "@/views/ChaincodeInteraction.vue";
import UserLayout from '@/views/Users.vue';
import ProfileCCPLayout from '@/views/ProfileCCPLayout.vue';
import RegisterLayout from '@/views/RegisterLayout.vue';
import NewChaincodeLayout from '@/views/NewChaincodeLayout.vue';
import InstallChaincodeLayout from '@/views/InstallChaincodeLayout.vue';
import BlocksLayout from '@/views/BlocksLayout.vue'
import TransactionsLayout from '@/views/TransactionsLayout.vue';
import MetricsLayout from '@/views/MetricsLayout.vue'
import NotificationsLayout from '@/views/NotificationsLayout.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginLayout,
  },
  {
    path: "/register",
    name: "RegisterLayout",
    component: RegisterLayout,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },



  {
    path: "/ledger/blocks",
    name: "BlocksLayout",
    component: BlocksLayout,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/ledger/transactions",
    name:"TransactionsLayout",
    component: TransactionsLayout,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/channels",
    name: "ChannelsLayout",
    component: Channels,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/chaincodes",
    name: "ChaincodesLayout",
    component: ChaincodesLayout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/chaincodes/new",
    name: "NewChaincodeLayout",
    component: NewChaincodeLayout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `/chaincode/:channelID/:chaincodeName`,
    name: "ChaincodeInteractionPanelLayout",
    component: ChaincodeInteractionPanelLayout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `/chaincode/:channelID/:chaincodeName/view`,
    name: "InstallChaincodeLayout",
    component: InstallChaincodeLayout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `/users`,
    name: "UserLayout",
    component: UserLayout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/ccp",
    name: "ProfileCCPLayout",
    component: ProfileCCPLayout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/metrics/index",
    name: "MetricsLayout",
    component: MetricsLayout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/events",
    name: "NotificationsLayout",
    component: NotificationsLayout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!IsLogged()) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (sessionStorage.getItem("user") == null) {
      next();
    } else {
      next({ name: "Dashboard" });
    }
  } else {
    next();
  }
});

export default router;
