


















































































































































import Vue from "vue";
import "@/assets/styles/custom_style.css";
import axios, { AxiosError, AxiosResponse } from "axios";
import { BackendURL } from "@/lib/auth";
import { WaitTime } from "@/lib/common";

export default Vue.extend({
  name: "BlocksLayout",

  data: () => ({
    blocksTableHeader: [
      {
        text: "Number",
        align: "start",
        value: "Number",
        sortable: true,
      },
      { text: "Data Hash", value: "DataHash", sortable: false },
      { text: "Block Hash", value: "BlockHash", sortable: false },
      {
        text: "Previous Hash",
        value: "PreviousHash",
        sortable: false,
      },
      {
        text: "Number Transactions",
        value: "NumTx",
        sortable: false,
      },
      { text: "Timestamp", value: "Timestamp", sortable: true },
    ],
    blocksTable: [],
    blocksSortBy: "Number",
    blocksSortDesc: true,

    firstBlockNumber: 0,
    numberBlocks: 10,
    countNoMoreBlocks: 0,

    dialogBlock: false,
    singleBlockTableHeader: [
      { text: "Name", align: "start", value: "name", sortable: false },
      { text: "Value", align: "end", value: "value", sortable: false },
    ],
    singleBlockTable: [] as any[],

    btnDisabled: false,
    skeletonActive: true,
  }),

  watch: {
    $activeChannel() {
      this.firstBlockNumber = 0;
      this.numberBlocks = 10;

      this.skeletonActive = true;
      this.getInitialInfo().then(() => {
        WaitTime(500).then(() => {
          this.skeletonActive = false;
        });
      });
    },
  },

  beforeMount() {
    this.getInitialInfo().then(() => {
      WaitTime(500).then(() => {
        this.skeletonActive = false;
      });
    });
  },

  methods: {
    async getInitialInfo() {
      const ch = Vue.prototype.$activeChannel;
      this.blocksTable = [];

      axios.defaults.withCredentials = true;

      try {
        const resp = await axios.get(`${BackendURL}/channels/${ch}/ledger/get`);
        this.blocksTable = Object.assign([], resp.data);
      } catch (err) {
        console.log(err);
        this.$root.$emit(
          "show-banner",
          "Could not retrieve blocks from server",
          false,
          "ERROR"
        );
      }

      this.firstBlockNumber = (this.blocksTable[0] as any).Number;
      this.numberBlocks = this.blocksTable.length;
    },

    openBlockViewer(block: any) {
      this.dialogBlock = true;
      this.singleBlockTable = [];
      const transactionsArray: string[] = [];
      Object.keys(block).forEach((key) => {
        if (key === "Transactions") {
          (block.Transactions as any[]).forEach((tx) => {
            transactionsArray.push(tx.TxID);
          });
          this.singleBlockTable.push({ name: key, value: transactionsArray });
        } else {
          this.singleBlockTable.push({ name: key, value: block[key] });
        }
      });
    },

    closeBlockViewer() {
      this.dialogBlock = false;
      this.singleBlockTable = [];
    },

    fetchMoreBlocks() {
      this.btnDisabled = true;

      const ch = Vue.prototype.$activeChannel;

      let sp = 0;
      const ep: number = this.firstBlockNumber;
      const offset: number = ep - 10;
      if (offset > 0) {
        sp = this.firstBlockNumber - 10;
      }

      if (offset < 0) {
        if (this.countNoMoreBlocks >= 1) {
          this.btnDisabled = false;
          return;
        }
        this.countNoMoreBlocks += 1;
      }

      axios.defaults.withCredentials = true;
      axios
        .get(`${BackendURL}/channels/${ch}/ledger/get?sp=${sp}&ep=${ep}`)
        .then((resp) => {
          const copyArray = Object.assign([], this.blocksTable);
          const newArray = (resp.data as any[]).concat(copyArray);
          this.blocksTable = Object.assign([], newArray);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          this.$root.$emit(
            "show-banner",
            "Could not fetch more blocks",
            false,
            "ERROR"
          );
        })
        .finally(() => {
          this.firstBlockNumber = (this.blocksTable[0] as any).Number;
          this.numberBlocks = this.blocksTable.length;
          this.btnDisabled = false;
        });
    },
  },
});
