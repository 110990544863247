










































































































































































































































































































































































































































































































































import Vue from "vue";
import "@/assets/styles/custom_style.css";
import axios, { AxiosError, AxiosResponse } from "axios";
import { BackendURL } from "@/lib/auth";

import MyWorker from "worker-loader!@/workers/install.worker.ts";
// import MyWorker from "../workers/event.worker.js";

interface IuploadPkg {
  name: string;
  version: string;
  file: File;
}

export default Vue.extend({
  name: "NewChaincodeLayout",

  data: () => ({
    packagesTableHeader: [
      { text: "Package name", align: "start", value: "chaincodeID" },
      { text: "Channel Name", value: "channel" },
      { text: "Creator Membership ID", value: "creatorMSP" },
      { text: "", value: "btn1", sortable: false },
      { text: "", value: "btn2", sortable: false },
      { text: "", value: "btn3", sortable: false },
    ],
    packagesTable: [] as any[],
    activeChannel: Vue.prototype.$activeChannel,
    deletePkgDialog: false,
    selectedPkg: "",
    uploadingPkg: false,
    uploadedPkg: {} as IuploadPkg,
    rules: {
      required: (value: any) => !!value || "Required.",
      noSpaces: (v: any) => {
        let length = 0;
        if (v) {
          length = (v as string).split(" ").length;
        }

        return length === 1 || "No spaces allowed";
      },
      validVersion: (v: string) => {
        const versionString: string = v ? v : "";
        const regex = /^[0-9]+((\.)[0-9]+){0,3}$/;
        const isNotZeros = Number(versionString.split(".").join("")) > 0;
        const isValid = regex.test(versionString) && isNotZeros;
        return isValid || "Invalid Version";
      },
      number: (v: any) =>
        Number.isInteger(Number(v)) || "Must be an integer number",
    },

    installedChaincodesTableHeader: [
      { text: "Package Name", align: "start", value: "label" },
      { text: "Package ID", value: "package_id" },
      { text: "", value: "btn_inspect" },
    ],
    installedChaincodesTable: [] as any[],

    inspectInstalledChaincodeDialog: false,
    inspectInstalledChaincodeSelected: {},
    inspectInstalledChaincodeSequence: 1,
    inspectInstalledChaincodeName: "",
    inspectInstalledChaincodeVersion: "",
    inspectCommitReadinessTableHeader: [
      { text: "Organization", align: "start", value: "org" },
      { text: "Status", value: "approve" },
    ],
    inspectCommitReadinessTable: [],
    btnDisabled: false,

    isLoadingSubmit: false,
    isLoadingCommit: false,
  }),

  watch: {
    $activeChannel(value) {
      this.activeChannel = value;
      this.packagesTable = [] as any[];
      this.getPkgs();
    },
  },

  beforeMount() {
    this.getPkgs();
    this.getInstalledChaincodes();
  },

  methods: {
    getPkgs() {
      axios.defaults.withCredentials = true;
      axios
        .get(`${BackendURL}/channels/${this.activeChannel}/packages/list`)
        .then((resp) => {
          if (resp.status !== 210) {
            this.packagesTable = resp.data;
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
          const text = "Cold not list packages";
          this.$root.$emit("show-banner", text, false, "ERROR");
        });
    },

    downloadPkg(pkgName: string) {
      this.btnDisabled = true;

      axios.defaults.withCredentials = true;
      axios.defaults.responseType = "blob";
      axios
        .get(
          `${BackendURL}/channels/${this.activeChannel}/packages/${pkgName}/get`
        )
        .then((resp: AxiosResponse) => {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${pkgName}.tar.gz`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err: AxiosError) => {
          console.log(err);
          const text = "Could not download package from server";
          this.$root.$emit("show-banner", text, false, "ERROR");
        })
        .finally(() => {
          this.btnDisabled = false;
        });
    },

    activeDeleteDialog(pkgName: string) {
      this.selectedPkg = pkgName;
      this.deletePkgDialog = true;
      this.btnDisabled = false;
    },

    deletePkg() {
      this.btnDisabled = true;

      const pkg = this.selectedPkg;
      axios.defaults.withCredentials = true;
      axios
        .get(
          `${BackendURL}/channels/${this.activeChannel}/packages/${pkg}/delete`
        )
        .then(() => {
          window.location.reload();
        })
        .catch((err: AxiosError) => {
          console.log(err);
          const text = "Could not delete package";
          this.$root.$emit("show-banner", text, false, "ERROR");
        })
        .finally(() => {
          this.btnDisabled = false;
        });
    },

    initUploadPkgWorker() {
      let isValid: boolean = (this.$refs as any).uploadPkgForm.validate();
      if (!isValid) {
        return;
      }
      this.btnDisabled = true;

      let text = `Uploading package ${this.uploadedPkg.name}:${this.uploadedPkg.version} at ${this.activeChannel}`;
      this.$root.$emit("show-banner", text, false, "INFO");

      // Close dialog to allow users to navigate freely while the installation takes place
      this.closeInspectDialog();
      this.btnDisabled = false;

      // Create worker
      let worker = new MyWorker();
      worker.postMessage({
        type: "submit-pkg",
        args: {
          file: this.uploadedPkg.file,
          channel: this.activeChannel,
          pkg: this.uploadedPkg.name,
          version: this.uploadedPkg.version,
        },
      });

      // Handle result
      worker.onmessage = (e: any) => {
        if (e.data.isOk) {
          text = `${this.uploadedPkg.name}:${this.uploadedPkg.version} uploaded at ${this.activeChannel}`;
          this.$root.$emit("show-banner", text, true, "SUCCESS");
          this.getPkgs();
        } else {
          text = "Could not submit package";
          this.$root.$emit("show-banner", text, false, "ERROR");
        }

        this.uploadingPkg = false;
        this.btnDisabled = false;
        worker.terminate();
      };
    },

    getInstalledChaincodes() {
      axios.defaults.withCredentials = true;
      axios
        .get(`${BackendURL}/channels/chaincodes`)
        .then((resp: AxiosResponse) => {
          const arrayInstalled: any[] = [];
          const responseArray: any[] = resp.data.installed_chaincodes
            ? resp.data.installed_chaincodes
            : [];

          if (responseArray.length === 0 || responseArray === undefined) return;

          responseArray.forEach((pkg) => {
            arrayInstalled.push({
              label: pkg.label,
              package_id: pkg.package_id,
              channels: pkg.references,
            });
          });

          this.installedChaincodesTable = arrayInstalled;
        })
        .catch((err: AxiosError) => {
          const text =
            "Could not retrieve installed chaincodes from server. Please contact the administrator";
          this.$root.$emit("show-banner", text, false, "ERROR");
        });
    },

    initInstall(chaincodeName: string, channel: string) {
      this.btnDisabled = true;
      this.$router.push(`/chaincode/${channel}/${chaincodeName}/view`);
      this.btnDisabled = false;
    },

    openInspectDialog(pkg: any) {
      this.btnDisabled = true;
      this.inspectInstalledChaincodeDialog = true;
      this.inspectInstalledChaincodeSelected = pkg;
      this.btnDisabled = false;
    },

    checkCommitReadiness() {
      const isValid = (this.$refs as any).readinessForm.validate();
      if (!isValid) return;

      this.btnDisabled = true;

      const body = {
        sequence: this.inspectInstalledChaincodeSequence,
        smartContractName: this.inspectInstalledChaincodeName,
        smartContractVersion: this.inspectInstalledChaincodeVersion,
        packageId: (this.inspectInstalledChaincodeSelected as any).package_id,
      };

      axios.defaults.withCredentials = true;
      axios
        .post(
          `${BackendURL}/channels/${this.activeChannel}/sc-commit-readiness`,
          body
        )
        .then((resp) => {
          this.inspectCommitReadinessTable = resp.data;
        })
        .catch((err: AxiosError) => {
          console.log(err);
          const text = err.response?.data as string;
          this.$root.$emit("show-banner", text, false, "ERROR");
        })
        .finally(() => {
          this.btnDisabled = false;
        });
    },

    approveChaincode() {
      const isValid = (this.$refs as any).readinessForm.validate();
      if (!isValid) return;

      this.isLoadingSubmit = true;
      this.btnDisabled = true;

      const body = {
        sequence: this.inspectInstalledChaincodeSequence,
        smartContractName: this.inspectInstalledChaincodeName,
        smartContractVersion: this.inspectInstalledChaincodeVersion,
        packageId: (this.inspectInstalledChaincodeSelected as any).package_id,
      };

      axios.defaults.withCredentials = true;
      axios
        .post(
          `${BackendURL}/channels/${this.activeChannel}/approve-chaincode`,
          body
        )
        .catch((err: AxiosError) => {
          console.log(err);
          this.closeInspectDialog();
          const text = err.response?.data as string;
          this.$root.$emit("show-banner", text, false, "ERROR");
        })
        .finally(() => {
          if (this.inspectCommitReadinessTable.length >= 1) this.checkCommitReadiness();
          this.isLoadingSubmit = false;
          this.btnDisabled = false;
        });
      1;
    },

    commitChaincode() {
      const isValid = (this.$refs as any).readinessForm.validate();
      if (!isValid) return;

      this.isLoadingCommit = true;
      this.btnDisabled = true;

      axios.defaults.withCredentials = true;
      axios
        .get(
          `${BackendURL}/channels/${this.activeChannel}/discover-peers-endpoints`
        )
        .then((resp) => {
          const arrayPeers = resp.data as any[];
          const peerNames: any[] = [];
          arrayPeers.forEach((peer) => {
            peerNames.push(peer[0]);
          });

          const body = {
            sequence: this.inspectInstalledChaincodeSequence,
            smartContractName: this.inspectInstalledChaincodeName,
            smartContractVersion: this.inspectInstalledChaincodeVersion,
            packageId: (this.inspectInstalledChaincodeSelected as any)
              .package_id,
            endorsersName: peerNames,
          };

          axios
            .post(
              `${BackendURL}/channels/${this.activeChannel}/commit-chaincode`,
              body
            )
            .catch((err: AxiosError) => {
              console.log(err);
              this.closeInspectDialog();
              const text = err.response?.data as string;
              this.$root.$emit("show-banner", text, false, "ERROR");
            })
            .finally(() => {
              this.getInstalledChaincodes();
              this.isLoadingCommit = false;
              this.btnDisabled = false;
            });
        })
        .catch((err: AxiosError) => {
          console.log(err);
          this.closeInspectDialog();
          const text = err.response?.data as string;
          this.$root.$emit("show-banner", text, false, "ERROR");
          this.isLoadingCommit = false;
          this.btnDisabled = false;
        });
    },

    closeInspectDialog() {
      this.inspectInstalledChaincodeDialog = false;
      this.inspectInstalledChaincodeSelected = {};
      this.inspectInstalledChaincodeName = "";
      this.inspectInstalledChaincodeSequence = 1;
      this.inspectCommitReadinessTable = [];
      this.inspectInstalledChaincodeVersion = "";
    },
  },
});
