import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "vuetify/dist/vuetify.min.css";


Vue.config.productionTip = false;


// Global scoped variable to select which channel is queried
const activeChannel = Vue.observable({ activeChannel: {} });
Object.defineProperty(Vue.prototype, "$activeChannel", {
  get() {
    return activeChannel.activeChannel;
  },
  set(value) {
    activeChannel.activeChannel = value;
  },
});




new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
