




































































import { BackendURL, IsLogged, RetriveUserInfo } from "@/lib/auth";
import { ListChannels } from "@/lib/common";
import axios from "axios";
import Vue from "vue";

export default Vue.extend({
  name: "LoginLayout",

  // Check if the user is logged in the platform. If true, hide login page
  beforeMount() {
    if (IsLogged()) {
      this.$router.push("/");
    }
  },

  data: () => ({
    email: "",
    password: "",
    isLoginWrong: false,
    rules: {
      required: (value: any) => !!value || "Required.",
      email: (value: any) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
  }),

  methods: {
    loginRequest() {
      const isValid: boolean = (this.$refs as any).form.validate();
      if (!isValid) {
        return;
      }

      const body = {
        userID: this.email,
        password: this.password,
      };
      axios.defaults.withCredentials = true;
      axios
        .post(BackendURL + "/login", body)
        .then((resp) => {
          if (resp.status === 200) {
            sessionStorage.setItem("isAuth", "true");
            sessionStorage.setItem("user", body.userID);

            // Get user information
            RetriveUserInfo()
              .then((resp) => {
                sessionStorage.setItem("profile", JSON.stringify(resp as any));

                // List available channels
                ListChannels()
                  .then(() => {
                    window.location.replace("/");
                  })
                  .catch((err) => console.error);
              })
              .catch((err) => {
                console.error(err);
              });
          }
        })
        .catch((err) => {
          this.isLoginWrong = true;
          if (err.response.status === 410) {
            const text = "Wrong user and/or password";
            this.$root.$emit("show-banner", text, false, "ERROR");
          } else {
            const text = "Could not connect to server";
            this.$root.$emit("show-banner", text, false, "ERROR");
          }
        });
    },
  },
});
