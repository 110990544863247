



















































































































































import Vue from "vue";
import "@/assets/styles/custom_style.css";
import axios, { AxiosError } from "axios";
import { BackendURL } from "@/lib/auth";

export default Vue.extend({
  name: "ProfileCCPLayout",

  data: () => ({
    ccp: "",
    uploading: false,

    fileUploaded: undefined,
    rules: {
      required: (value: any) => !!value || "Required.",
    },

    btnDisabled: false,
  }),

  beforeMount() {
    this.getCcpFromServer();
  },

  methods: {
    getCcpFromServer() {
      axios.defaults.withCredentials = true;
      axios
        .get(`${BackendURL}/database/ccp/get`)
        .then((resp) => {
          this.ccp = JSON.stringify(JSON.parse(resp.data.ccp), null, "\t");
        })
        .catch((err: AxiosError) => {
          console.log(err);
          const text = "Could not get connectio profile from server";
          this.$root.$emit("show-banner", text, false, "ERROR");
        });
    },

    downloadFile() {
      const file = Object.assign({}, JSON.parse(this.ccp));
      const blob = new Blob([JSON.stringify(file)], {
        type: "application/json",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.click();
    },

    uploadFile() {
      this.uploading = true;
    },

    closeUploadTag() {
      this.uploading = false;
      this.fileUploaded = undefined;
    },

    submitFile() {
      this.btnDisabled = true;
      const isValid: boolean = (this.$refs as any).uploadForm.validate();

      if (!isValid) return;

      let file: File = this.fileUploaded as any;
      file.text().then((value) => {
        const obj = JSON.parse(value);

        // Check if there are any changes from the previous version
        const areTheSame: boolean = value === this.ccp;

        if (areTheSame) {
          const text =
            "There are no differences between the submitted connection profile and the current one";
          this.$root.$emit("show-banner", text, false, "ERROR");

          return;
        }

        axios.defaults.withCredentials = true;
        axios
          .post(`${BackendURL}/database/ccp/update`, { ccp: obj })
          .then(() => {
            window.location.reload();
            this.btnDisabled = false;
          })
          .catch((err: AxiosError) => {
            console.error(err.message);
            const text = "Could not update connection profile";
            this.$root.$emit("show-banner", text, false, "ERROR");
            this.btnDisabled = false;
          });
      });
    },
  },
});
