









































































































































































import { BackendURL, UserInfo, RetriveUserInfo } from "@/lib/auth";
import axios, { AxiosError } from "axios";
import { Certificate } from "@fidm/x509";
import Vue from "vue";

interface IProfileSmall {
  name: string;
  value: string;
  attributes: string[];
}

export default Vue.extend({
  name: "Profile",

  data: () => ({
    userID: sessionStorage.getItem("user"),
    profileInfo: [] as IProfileSmall[],
    userCert: "" as string,
    certAttrs: [] as any[],
    deleteBanner: false,
  }),

  beforeMount() {
    RetriveUserInfo()
      .then((resp) => {
        const profileObject: any = resp;        

        // Get certificate and attributes from the certificate
        this.userCert = (profileObject as UserInfo).certificate;
        delete profileObject.certificate;

        this.getCertificateAttrs();
        

        // Push the email
        this.profileInfo.push({
          name: "email",
          value: this.userID as string,
          attributes: [],
        });

        Object.keys(profileObject).forEach((element) => {
          if (element === "max_enrollments") {
            element = "max enrollments";
            profileObject[element] = "infinite";
          }
          this.profileInfo.push({
            name: element,
            value: profileObject[element],
            attributes: [],
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  },

  methods: {
    deleteUserFromApp() {
      axios.defaults.withCredentials = true;
      axios
        .get(`${BackendURL}/database/users/me/delete`)
        .then(() => {
          this.$router.push("/logout");
        })
        .catch((err: AxiosError) => {
          console.log(err.message);
          const text = "Could not delete user from the database";
          this.$root.$emit("show-banner", text, false, "ERROR");
        });
    },

    getCertificateAttrs() {
      const cert = Certificate.fromPEM(Buffer.from(this.userCert));
      const attrs = JSON.parse(
        cert.extensions[cert.extensions.length - 1].value.toString()
      );

      const certAttrs: any[] = [];
      Object.keys(attrs.attrs).forEach((key) => {
        certAttrs.push({ name: key, value: attrs.attrs[key] });
      });      

      this.certAttrs = certAttrs;
    },
  },
});
