



































































































































































































































































































import { BackendURL } from "@/lib/auth";
import axios, { AxiosError } from "axios";
import Vue from "vue";

export default Vue.extend({
  name: "ProfileCard",

  data: () => ({
    userInfoData: {} as any,
    attrsTableHeader: [
      {
        text: "Name",
        align: "start",
        value: "name",
        width: "37%",
      },
      { text: "Value", value: "value", width: "37%" },
      { text: "Ecert", value: "ecert", width: "16%" },
      { text: "", value: "btn", sortable: false, width: "10%" },
    ],
    readMode: true,
    newAttr: { name: "", value: "", ecert: false },
    rules: {
      required: (value: any) => !!value || "Required.",
    },
    valid: true,
  }),

  props: {
    user: {
      type: String,
      required: true,
    },
  },

  beforeMount() {
    this.getUserInfo();
    this.readMode = true;
  },

  methods: {
    getUserInfo() {
      axios.defaults.withCredentials = true;
      axios
        .get(`${BackendURL}/ca/users/${this.user}/get`)
        .then((resp) => {
          const infoObj = Object.assign({}, resp.data.result);

          // Replace field names
          Object.defineProperty(
            infoObj,
            "enrollmentID",
            Object.getOwnPropertyDescriptor(infoObj, "id") as any
          );
          delete infoObj["id"];

          Object.defineProperty(
            infoObj,
            "role",
            Object.getOwnPropertyDescriptor(infoObj, "type") as any
          );
          delete infoObj["type"];

          this.userInfoData = Object.assign({}, infoObj);
        })
        .catch((err: AxiosError) => {
          console.log(err.message);
        });
    },

    sendCloseEvent() {
      this.$emit("close-view-banner");
      this.readMode = true;
    },

    editProfile() {
      this.readMode = false;
    },

    addNewAttr() {
      const isValid: boolean = (this.$refs as any).formAttrs.validate();

      if (isValid) {
        (this.userInfoData.attrs as any[]).push({ ...this.newAttr });
      }
    },

    submitUpdate() {
      const isValid: boolean = (this.$refs as any).formSubmit.validate();

      if (isValid) {
        const body = JSON.parse(JSON.stringify(this.userInfoData) as any);

        delete body.caname;

        const listIndexesToRemove: number[] = [];

        (body.attrs as any[]).forEach((attr, index) => {
          const isDisabled: boolean = this.isFieldDisabled(attr.name);
          if (isDisabled) {
            listIndexesToRemove.push(index);
          }
        });

        // Remove values using their index
        const indexSet = new Set(listIndexesToRemove);
        body.attrs = (body.attrs as any[]).filter(
          (value, i) => !indexSet.has(i)
        );

        axios.defaults.withCredentials = true;
        axios
          .post(
            `${BackendURL}/ca/users/${
              this.userInfoData.enrollmentID as string
            }/update`,
            body
          )
          .then((resp) => {
            if (resp.status !== 200) {
              this.$emit("error", "Could not update user profile");
            }
            window.location.reload();
          })
          .catch((err: AxiosError) => {
            console.log(err);
            this.$emit("error", err.message);
          });
      }
    },

    removeAttr(attr: any) {
      const index = (this.userInfoData.attrs as any[])
        .map((fields: any) => fields.name)
        .indexOf(attr.name);

      (this.userInfoData.attrs as any[]).splice(index, 1);
    },

    isFieldDisabled(name: string) {
      return (
        name === "hf.Affiliation" ||
        name === "hf.Type" ||
        name === "hf.EnrollmentID"
      );
    },
  },
});
