


























































































































































































































































































































































































import { BackendURL } from "@/lib/auth";
import axios, { AxiosError } from "axios";
import Vue from "vue";
import "@/assets/styles/custom_style.css";

import ProfileCard from "@/components/ProfileCard.vue";

interface IusersTable {
  id: string;
  type: string;
  affiliation: string;
}

interface IkeyValueAttribute {
  name: string;
  value: string;
  ecert?: boolean;
}

interface IregisterRequest {
  userID: string;
  opts: {
    enrollmentID: string;
    enrollmentSecret?: string;
    role?: string;
    affiliation: string;
    maxEnrollments?: number;
    attrs?: IkeyValueAttribute[];
  };
}

export default Vue.extend({
  name: "UsersLayout",
  components: {
    ProfileCard,
  },

  data: () => ({
    usersTableHeader: [
      {
        text: "User ID",
        align: "start",
        value: "id",
        sortable: true,
      },
      { text: "Type", value: "type", sortable: true },
      { text: "Affiliation", value: "affiliation", sortable: true },
      { text: "", value: "viewBtn", filterable: false, sortable: false },
      { text: "", value: "deleteBtn", filterable: false, sortable: false },
    ],
    usersTable: [{}] as IusersTable[],
    caName: "",
    registerDialog: false,
    registerHasAttributes: false,
    vRegisterListAttrs: { ecert: false } as IkeyValueAttribute,
    registerListAttrs: [] as IkeyValueAttribute[],
    tableAttrsHeader: [
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      { text: "Value", value: "value" },
      { text: "Ecert", value: "ecert" },
      { text: "", value: "" },
    ],
    activeTableAttrs: false,
    form: { userID: "", opts: {} } as IregisterRequest,
    rules: {
      required: (value: any) => !!value || "Required.",
      counter: (value: any) => value.length <= 20 || "Max 20 characters",
      email: (value: any) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      noSpaces: (v: any) => {
        let length = 1;
        if (v) {
          length = (v as string).split(" ").length;
        }

        return length === 1 || "No spaces allowed";
      },
    },

    deleteBanner: false,
    activeUser: undefined,

    viewBanner: false,

    skeletonActive: true,

    btnDisabled: true,
  }),

  beforeMount() {
    this.getUsersInfo();
    this.skeletonActive = false;
  },

  methods: {
    addAttrToList() {
      const attr = { ...this.vRegisterListAttrs };

      // Check that there are not empty values in the attributes object
      const values = Object.entries(attr).filter(
        ([k, v], i) => v !== null && v !== undefined && v !== ""
      );

      if (values.length === 3) {
        // Remove space from attribute name
        attr.name = attr.name.replace(/\s/g, "");

        // Check if attribute already exists in the list
        const index = this.registerListAttrs
          .map((fields) => fields.name)
          .indexOf(attr.name);

        if (index >= 0) {
          this.$set(this.registerListAttrs, index, attr);
        } else {
          this.registerListAttrs.push(attr);
        }

        this.activeTableAttrs = true;
      }
    },

    removeRegisterAttr(attr: IkeyValueAttribute) {
      const index = this.registerListAttrs
        .map((fields) => fields.name)
        .indexOf(attr.name);

      // Remove item
      this.registerListAttrs.splice(index, 1);

      if (this.registerListAttrs.length < 0) {
        this.activeTableAttrs = false;
      }
    },

    getUsersInfo() {
      axios.defaults.withCredentials = true;
      axios
        .get(`${BackendURL}/ca/users/list`)
        .then((resp) => {
          this.btnDisabled = false;
          // Check success message
          if (!resp.data.success) {
            this.$root.$emit(
              "show-banner",
              "Could not list any user (Maybe you do not have enough privileges to do this action)",
              false,
              "WARNING"
            );
            return;
          }
          this.caName = resp.data.result.caname;
          this.usersTable = resp.data.result.identities as IusersTable[];
        })
        .catch((err) => {
          const dataError = (err as AxiosError).response?.data
            ? (err as AxiosError).response?.data
            : null;

          const regex = /\[\[{"code":71,"message":"Authorization failure"}\]\]/;
          if (dataError && regex.exec(dataError)) {
            this.usersTable = [];
            this.btnDisabled = true;
            this.$root.$emit(
              "show-banner",
              "Could not list any user (User does not have enough privileges to do this action)",
              false,
              "WARNING"
            );
          } else {
            this.$root.$emit(
              "show-banner",
              "Could not list users from server",
              false,
              "Error"
            );
          }
        });
    },

    registerNewUser() {
      const isValid: boolean = (this.$refs as any).registerForm.validate();

      if (!isValid) return;

      const body = { ...this.form };

      if (this.registerListAttrs.length > 0) {
        body.opts.attrs = this.registerListAttrs;
      }

      axios.defaults.withCredentials = true;
      axios
        .post(`${BackendURL}/ca/users/register`, body)
        .then((resp) => {
          if (resp.status !== 200) {
            this.$root.$emit(
              "show-banner",
              "Could not register user (Maybe you do not have enough privileges)",
              false,
              "WARNING"
            );
          }
          this.registerDialog = false;
          window.location.replace("/users");
        })
        .catch((err) => {
          console.log(err);
          this.$root.$emit(
            "show-banner",
            "Could not register user in server",
            false,
            "Error"
          );
        });
    },

    viewUser(user: any) {
      this.viewBanner = true;
      this.activeUser = user.id;
    },

    deleteUser(isOpen: boolean, user: any, completeDelete: boolean) {
      if (!isOpen) {
        this.deleteBanner = true;
        this.activeUser = user.id;
      } else {
        if (completeDelete) {
          axios.defaults.withCredentials = true;
          axios
            .get(`${BackendURL}/ca/users/${this.activeUser}/delete`)
            .then((resp) => {
              if (resp.status !== 200) {
                this.$root.$emit(
                  "show-banner",
                  "Could not delte user from server (Maybe you do not have enough privileges)",
                  false,
                  "WARNING"
                );
              } else {
                window.location.reload();
              }
            })
            .catch((err: AxiosError) => {
              console.log(err);
              this.$root.$emit(
                "show-banner",
                "Could not delete user from server",
                false,
                "Error"
              );
            });
        }
        this.activeUser = undefined;
        this.deleteBanner = false;
      }
    },

    handleError(err: string) {
      this.$root.$emit("show-banner", err, false, "Error");
    },
  },
});
