import axios from "axios";

export interface Login {
  userID: string;
  password: string;
}

export interface UserInfo {
  org: string;
  id: string;
  type: string;
  affiliation: string;
  certificate: string;
  networkID: string;
  attrs: Array<any>;
}

export const BackendURL = process.env.VUE_APP_BACKEND_API;

export function IsLogged(): boolean {
  if (sessionStorage.getItem("isAuth") === null) {
    return false;
  }
  return true;
}

export const RetriveUserInfo = () => {
  return new Promise((resolve, reject) => {
    if (IsLogged()) {
      const profile: UserInfo = {} as UserInfo;

      axios.defaults.withCredentials = true;
      axios
        .get(`${BackendURL}/ca/users/me`)
        .then((resp) => {
          profile.id = resp.data.enrollmentID as string;
          profile.certificate = resp.data.certificate as string;
          profile.org = resp.data.org as string;
          profile.networkID = resp.data.networkID as string;
          resolve(profile);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      reject(new Error("User is not logged in"));
    }
  });
};
