




import { IsLogged } from "@/lib/auth";
import Vue from "vue";

export default Vue.extend({
  name: "Home",
  beforeCreate() {
    if (IsLogged()) {
      window.location.replace("/dashboard");
    } else {
      window.location.replace("/login");
    }
  },
});
