




























































































































































































































































import Vue from "vue";

import "@/assets/styles/custom_style.css";
import axios, { AxiosError, AxiosResponse } from "axios";
import { BackendURL } from "@/lib/auth";
import { WaitTime } from "@/lib/common";

export default Vue.extend({
  name: "TransactionsLayout",

  data: () => ({
    totalNumTx: 0,
    searchCount: 1,

    filterTxTable: "",

    txTableHeader: [
      {
        text: "Timestamp",
        align: "start",
        value: "Timestamp",
        filterable: false,
        sortable: true,
      },
      {
        text: "Transaction ID",
        value: "TxID",
        filterable: true,
        sortable: false,
      },
      {
        text: "Creator MSP",
        value: "CreatorMSP",
        filterable: false,
        sortable: true,
      },
      {
        text: "Type",
        value: "Type",
        filterable: false,
        sortable: false,
      },
      {
        text: "Chaincode",
        value: "ChaincodeName",
        filterable: false,
        sortable: false,
      },
      {
        text: "",
        value: "inspect",
        filterable: false,
        sortable: false,
      },
    ],
    txTable: [],
    txSortBy: "Timestamp",
    txSortDesc: true,

    dialogTx: false,

    singleTxTableHeader: [
      { text: "Name", align: "start", value: "name", sortable: false },
      { text: "Value", align: "start", value: "value", sortable: false },
    ],
    singleTxTable: [] as any[],

    btnDisabled: false,

    showSearchResult: false,
    searchTx: "",
    searchTxTable: [] as any[],
    searchRules: {
      required: (value: any) => !!value || "Required.",
      noSpaces: (v: any) => {
        let length = 1;
        if (v) {
          length = (v as string).split(" ").length;
        }

        return length === 1 || "No spaces allowed";
      },
      hex: (v: string) =>
        /^[0-9A-F]{64}$/i.test(v) || "Must be an hexadecimal string",
    },

    skeletonActive: true,
  }),

  watch: {
    $activeChannel() {
      this.totalNumTx = 0;
      this.filterTxTable = "";
      this.searchCount = 0;
      this.btnDisabled = false;
      this.getInitData().then(() => {
        WaitTime(500).then(() => {
          this.skeletonActive = false;
        });
      });
    },
  },

  beforeMount() {
    this.getInitData().then(() => {
      WaitTime(500).then(() => {
        this.skeletonActive = false;
      });
    });
  },

  methods: {
    async getInitData() {
      const channelID = Vue.prototype.$activeChannel;
      (this.txTable = []), (axios.defaults.withCredentials = true);

      axios.defaults.withCredentials = true;
      try {
        const resp = await axios.get(
          `${BackendURL}/channels/${channelID}/ledger/transactions/get`
        );

        this.totalNumTx = resp.data.numTx;
        this.txTable = Object.assign([], resp.data.tx);
      } catch (err) {
        console.log(err);
        this.$root.$emit(
          "show-banner",
          "Could not retrieve transactions from server",
          false,
          "ERROR"
        );
      }
    },

    viewMoreTx(tx: any) {
      this.dialogTx = true;
      this.singleTxTable = [];

      Object.keys(tx).forEach((key) => {
        this.singleTxTable.push({
          name: key,
          value: tx[key],
        });
      });
    },

    closeTxViewer() {
      this.singleTxTable = [];
      this.dialogTx = false;
    },

    fetchMoreTx() {
      this.btnDisabled = true;
      const channelID = Vue.prototype.$activeChannel;
      const sp =
        this.totalNumTx - 10 * this.searchCount - 10 > 0
          ? this.totalNumTx - 10 * this.searchCount - 10
          : 0;
      const ep =
        this.totalNumTx - 10 * this.searchCount > 0
          ? this.totalNumTx - 10 * this.searchCount
          : 0;

      axios.defaults.withCredentials = true;
      axios
        .get(
          `${BackendURL}/channels/${channelID}/ledger/transactions/get?sp=${sp}&ep=${ep}`
        )
        .then((resp) => {
          const newArray = resp.data.tx.concat(this.txTable);
          this.txTable = Object.assign([], newArray);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          this.$root.$emit(
            "show-banner",
            "Could not retrieve more transactions from server",
            false,
            "ERROR"
          );
        })
        .finally(() => {
          this.btnDisabled = false;
          this.searchCount++;
        });
    },

    searchTxFn() {
      const isValid: boolean = (this.$refs as any).searchForm.validate();
      if (!isValid) return;

      this.btnDisabled = true;

      const channel = Vue.prototype.$activeChannel;
      const txID = this.searchTx;

      // Check if the transaction has already been fetched
      const arrayCpy: any[] = Object.assign([], this.txTable);
      let isInArray = arrayCpy.some((tx) => {
        if (tx.TxID === txID) {
          return tx;
        }
      });

      if (isInArray) {
        const item: any[] = arrayCpy.filter((tx) => tx.TxID === txID);
        this.searchTxTable = ([] as any[]).concat(item);
        this.btnDisabled = false;
        return;
      }

      // Fetch transaction from server
      axios.defaults.withCredentials = true;
      axios
        .get(
          `${BackendURL}/channels/${channel}/ledger/transactions/${txID}/get`
        )
        .then((resp) => {
          this.searchTxTable.push(resp.data);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          this.$root.$emit(
            "show-banner",
            "Could not find transaction in server",
            false,
            "ERROR"
          );
        })
        .finally(() => {
          this.showSearchResult = true;
          this.btnDisabled = false;
        });
    },

    clearSearchTable() {
      this.searchTx = "";
      this.searchTxTable = [];
    },
  },
});
