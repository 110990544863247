





































































import { IsLogged } from "@/lib/auth";
import Vue from "vue";

export default Vue.extend({
  name: "VerticalNavBar",
  data: () => ({
    drawer: false,
    logo: "https://deustotech.deusto.es/wp-content/themes/deustotech/assets/images/deustotech_logo_full.svg",
    groupsIcons: {
      General: "mdi-home",
      Ledger: "mdi-book-open",
      Management: "mdi-tune",
    },

    isLogged: false,

    tabsGroups: [] as any[],
  }),

  props: {
    tabs: Object,
  },

  mounted() {
    this.isLogged = IsLogged();
  },

  computed: {
    groups: function () {
      return Object.keys(this.tabs);
    },
  },

  methods: {
    goTo(path: string): void {
      this.$router.push(path);
    },
  },
});
