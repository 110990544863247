import axios from "axios";
import { BackendURL } from "./auth";

export const ListChannels = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    const title = "List of channels";
    let list: string[] = [];

    const cacheChannels = sessionStorage.getItem("channels");
    if (cacheChannels !== null) {
      list = JSON.parse(cacheChannels as string);
      if (list.length < 1) {
        reject(new Error("User does not have any available channels"));
        return;
      }
      resolve({ title: title, channels: list });
    }

    axios.defaults.withCredentials = true;
    axios
      .get(`${BackendURL}/channels/list`)
      .then((resp) => {
        list = resp.data as Array<string>;
        sessionStorage.setItem("channels", JSON.stringify(list));
        resolve({ title: title, channels: list });
      })
      .catch((err) => {
        reject(err);
      });
  });
};



export const WaitTime =  (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};