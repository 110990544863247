















































import { BackendURL } from "@/lib/auth";
import axios from "axios";
import Vue from "vue";

import "@/assets/styles/custom_style.css";

interface ItableOrgs {
  mspID: string;
  endpoint: string;
  name: string;
  ledger?: string;
}

export default Vue.extend({
  name: "ChannelsLayout",

  data: () => ({
    currentChannel: Vue.prototype.$activeChannel,
    tablePeersHeader: [
      {
        text: "Organization(MSPID)",
        align: "start",
        value: "mspID",
      },
      { text: "Endpoint", value: "endpoint" },
      { text: "Name", value: "name" },
      { text: "Ledger Height", value: "ledger" },
    ],
    tablePeers: [{}] as ItableOrgs[],

    tableOrderersHeader: [
      {
        text: "Organization(MSPID)",
        align: "start",
        value: "mspID",
      },
      { text: "Endpoint", value: "endpoint" },
      { text: "Name", value: "name" },
    ],
    tableOrderers: [{}] as ItableOrgs[],

    skeletonActive: true,
  }),

  beforeMount() {
    this.getChannelInfo();
    this.skeletonActive = false;
  },

  watch: {
    $activeChannel() {
      this.currentChannel = Vue.prototype.$activeChannel;
      this.skeletonActive = true;
      this.getChannelInfo();
      this.skeletonActive = false;
    },
  },

  methods: {
    getChannelInfo() {
      this.tablePeers = [];
      this.tableOrderers = [];
      axios.defaults.withCredentials = true;
      axios
        .get(`${BackendURL}/channels/${this.currentChannel}/discover`)
        .then((resp) => {
          const peersByOrg = resp.data.peers_by_org;
          const orderersByOrg = resp.data.orderers;

          Object.keys(peersByOrg).forEach(async (org) => {
            const peers = peersByOrg[org].peers as any[];
            peers.forEach((peer) => {
              this.tablePeers.push({
                mspID: peer.mspid,
                endpoint: peer.endpoint,
                name: peer.name,
                ledger: peer.ledgerHeight.low,
              });
            });
          });

          Object.keys(orderersByOrg).forEach(async (org) => {
            const orderers = orderersByOrg[org].endpoints as any[];
            orderers.forEach((orderer) => {
              this.tableOrderers.push({
                mspID: org,
                endpoint: `${orderer.host}:${orderer.port}`,
                name: orderer.name,
              });
            });
          });
        })
        .catch((err) => {
          console.log(err);
          const text = "Could not list channels";
          this.$root.$emit("show-banner", text, false, "ERROR");
        });
    },
  },
});
